body {
  background-color: #ffffff !important;
}

//login styles

.LoginContainer {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
}

.circle_box {
  border-radius: 50% !important;
  width: 701px;
  height: 701px;
  // right: 30% !important;
  align-items: center !important;
  background-color: #F7931E !important;
}

.kbox {
  width: 260px;
  height: 300px;
  position: fixed;
  bottom: 75%;
  border-radius: 10%;
}

.k_login_kubera {
  padding: 20%;
}

.login_form_container {
  background: transparent !important;
  margin-top: 140px;
  position: fixed;
  align-items: center;
  box-shadow: none !important;
}

.aliados_title_container {
  position: fixed;
  margin-top: 60px;
  width: 250px;
}

.aliados_title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 2%;
}

.form_container {
  border: 6px solid white;
  width: 550px;
  height: 420px;
  margin-top: 15%;
  border-radius: 10%;
  padding: 10%;
}

.mail_input {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
}

.password_input {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
}

//sing-up styles
.register-container {
  margin-top: 2.5% !important;
}

.left-container {
  background: transparent;
}

.right-container {
  background: blue;
}

.left-circle {
  border-radius: 50% !important;
  height: 100%;
  width: 50%;
  position: fixed;
  right: 75%;
  bottom: -1%;
  background: #F7931E !important;
}

.form-register {
  width: 40%;
  left: 20%;
  top: 2.5%;
  border-radius: 45px !important;
}

.form-register-container {
  border: 7px solid #F7931E !important;
  width: 100%;
  border-radius: 45px;
}

.title-form-register {
  background: #F7931E !important;
  position: absolute;
  left: 30%;
  padding: 14px !important;
}

.k_register_kubera {
  position: absolute;
  left: 55%;
  top: 25%;
}

//barra izquierda de navegacion

.kubera-nav {
  margin: 0% !important;
  height: 100% !important;
  border-radius: 0% !important;
}

.Kubera_left_bar {
  width: 100%;
  height: 20%;
  background: orange !important;
  display: flex;
  justify-content: center;
}

.icon_left_bar {
  width: 5rem;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}

.sidenav_li {
  padding: 2% !important;
  // display: flex !important;
  justify-content: center !important;
  // align-items: center !important;
  // height: 55% !important;
  margin-bottom: 10%;
  height: 25% !important;
}

.sidenav_bg_li {
  background: #242A35 !important;
  width: 60px !important;
  height: 60px !important;
  display: flex !important;
  flex-direction: row;
  padding: 5% !important;
  border-radius: 50% !important;
  align-content: center;
  justify-content: center;
}

.sidnav_icon {
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 100% !important;
}

.sidenav_name_route {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625;
  letter-spacing: 6.00938em;
  position: fixed;
  background: #F6AD55;
  height: 8%;
  padding: 5px;
  border-end-end-radius: 30px;
  border-start-end-radius: 30px;
  margin-left: 2.8%;
  margin-right: 1%;
  z-index: -1;
  padding-left: 2.5%;
  padding-right: 0.5%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  display: none;
}

.sidenav_bg_li:hover>.sidenav_name_route {
  display: flex;
  left: 1%;
  height: 50px;
  margin-bottom: 1.5px;
}

.sidenav_bg_li:hover {
  background: #F7931E !important;
}

//chart/cards superiores

.total_card {
  background: transparent !important;
}

.big_card {
  background-color: #F7931E !important;
  border-radius: 15px;
  width: 200px;
  height: 200px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  align-content: flex-end !important;
}

.sub_porcentaje {
  color: white !important;
  display: flex;
  background: #FBB03B !important;
  border-radius: 15px;
  padding: 4px;
}

.box_container_card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.box_container_card span {
  font-size: 18px !important;
  color: white;

}

.box_container_card .icon_total {
  font-size: 250% !important;
  color: white;

}

.tree_card {
  background: transparent !important;
  box-shadow: none !important;
  margin-top: 12%;
}

.icon_box_tree_card {
  margin-right: 12px;

}

.text_box_tree_card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.text_box_tree_card span {
  font-size: 18px;
  font-weight: 600;
}

.percentaje_tree_card {
  background: orange !important;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  margin-top: 5px !important;
  padding: 2px;
  font-size: 8px;
}

// grafic cards

.grafic_card_grey .chart_line_customize {
  background: grey !important;
}

#radiusCard {
  border-radius: 32px;
}

.box_container_grafic_card {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

//card/contenedor para el boton de registro de algo(producto, admin, etc)
// se ubica arriba de las tablas en la ventana que se necesite

.card_form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px;
  border-radius: 32px;
  background: #F7931E !important;
  background-color: #F7931E !important;
}

.button_form_cards {
  background: #FBB03B;
  border: none;
  padding-right: 3.5%;
  padding-left: 3.5%;
  border-radius: 15px;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(255, 153, 0, 0.4);

  font-weight: 600 !important;
  font-size: 14px !important;
}

.button_form_cards:hover {
  background: #FBB03B;
  border: none;
  padding-right: 3.6%;
  padding-left: 3.6%;
  border-radius: 15px;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(255, 153, 0, 0.4);

  font-weight: 600 !important;
  font-size: 14.2px !important;
  cursor: pointer;
}

.button_form_cards_title {
  font-weight: 700;
  font-size: 20px;
}

.icon_form_card {
  color: white !important;
}


//Multicatalogo

.card_form_product {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px;
  border-radius: 32px;
  background: #F7931E !important;
  background-color: #F7931E !important;
  width: 200px;
  height: auto;
  font-weight: 600;
}

.card_form_product_no_activate {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px;
  border-radius: 32px;
  background: #a48665 !important;
  background-color: #a48665 !important;
  width: 200px;
  height: auto;
  font-weight: 600;
}

.product_modal {
  display: flex;
  flex-wrap: wrap;
}

//WALLET

.button_retiro {
  height: 100%;
  margin-top: 0px !important;
}

.retirar .button_retirar {
  width: 100%;
  height: 120%;
  border-radius: 32px;
  background: #344767 !important;
  background-color: #344767 !important;
  border: none;
  margin-top: -24px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 600;
  color: white;
}

.button_retirar:hover {
  cursor: pointer;
  background: #4f6386c6 !important;
  background-color: #4f6386c6 !important;
}

.money {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//FORM PRODUCTOS

.left_form_product {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  align-items: flex-start !important;
}

.container_FORM div div div div div div input {
  background: white !important;
  border-radius: 6px;
}

//CATEGORIAS EN SUPERADMIN

.category_name_form div input {
  background: white !important;
  border-radius: 6px !important;
  color: black !important;
}