body body {
    font-family: "Karla", sans-serif;
    background-color: #d0d0ce !important;
    min-height: 100vh;
}


.brand-wrapper {
    margin-bottom: 19px;

    .logo {
        height: 100px;
    }
}

.login-card {
    border: 0 !important;
    border-radius: 27.5px !important;
    box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
    overflow: hidden;
    // height: 650px;


    &-img {
        border-radius: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .card-body {
        padding: 45px 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 422px) {
            padding: 35px 24px;
        }
    }

    &-description {
        font-size: 25px;
        color: #000;
        font-weight: 600;
        // margin-bottom: 23px;
    }

    form {
        max-width: 326px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form-control {
        border: 1px solid #d5dae2;
        padding: 15px 25px;
        // margin-bottom: 20px;
        min-height: 45px;
        font-size: 13px;
        line-height: 15;
        font-weight: normal;
        width: 380px;

        &::placeholder {
            color: #919aa3;
        }
    }

    .login-btn {
        padding: 13px 20px 12px;
        background-color: #F7931E;
        border-radius: 4px;
        font-size: 17px;
        font-weight: bold;
        line-height: 20px;
        color: #fff;
        // margin-bottom: 24px;
        width: 160px !important;


        &:hover {
            border: 1px solid #000;
            background-color: transparent;
            color: #000;
        }
    }

    .forgot-password-link {
        font-size: 14px;
        color: #919aa3;
        margin-bottom: 12px;
        width: 220px;
        padding-left: 5%;
        padding-top: 3.6%;
    }

    &-footer-text {
        font-size: 16px;
        color: #000;
        margin-bottom: 60px;

        @media (max-width: 767px) {
            margin-bottom: 24px;
        }
    }

    &-footer-nav {
        a {
            font-size: 14px;
            color: #919aa3;
        }
    }
}

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.link_register {
    color: #F7931E;
    margin-left: 5px;
}

.body_register {
    background: linear-gradient(180deg, rgba(219,134,36,1) 0%, rgba(219,134,36,1) 51%, rgba(181,111,31,1) 100%) !important; 
}

.form-group p {
    margin-bottom: 0px;
}

#register-footer {
    margin-bottom: -15px !important;
}